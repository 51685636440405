<i18n>
ru:
  error: 'Невозможно загрузить карту, номер ошибки #1map'
ua:
  error: 'Неможливо перевірити користувача, номер помилки #1map'
us:
  error: 'Failed to validate user, the error number #1map'
</i18n>

<template>
  <maps-yandex-maps-address v-if="appConfig.VueSettingsPreRun.MapsProvider === 'yandex'" />
  <span
    v-else
    class="v-error-color"
    v-html="translate('mapsAddress.error')"
  />
</template>

<script setup lang="ts">
const appConfig = useAppConfig()
const { translate } = useI18nSanitized()
</script>
