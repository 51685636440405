<template>
  <maps-yandex-maps-polygon-common
    :click-callback="clickCallback"
    :coords="clientStore.ClientState.data?.SelectedDeliveryAddress?.AddressCoordinates"
    type="delivery"
  >
    <maps-common-delivery-icon />
  </maps-yandex-maps-polygon-common>
</template>

<script setup lang="ts">
import type { DistrictsPrice } from '~types/addressStore'

import type { DomEvent, DomEventHandlerObject } from '@yandex/ymaps3-types/imperative/YMapListener'

const clientStore = useClientStore()
const mapsStore = useMapsStore()
const popupStore = usePopupStore()

const { translate } = useI18nSanitized()
const { fromPopup } = useInstance()

async function clickCallback(object: DomEventHandlerObject | undefined, event: DomEvent): Promise<void> {
  const district: DistrictsPrice | undefined = object?.entity?.properties?.district as
    | DistrictsPrice
    | undefined
  if (district) {
    const geocode = await mapsStore.geocodeYmap(event.coordinates.toString(), null)

    if (geocode.response.GeoObjectCollection.featureMember.length === 0) {
      if (!fromPopup.value) {
        await popupStore.showError(translate('yandexMapsAutocomplete.incorrectGeocode'))
      }

      return
    }
    mapsStore.updateDeliveryCoordsWithGeocode(
      event.coordinates,
      geocode.response.GeoObjectCollection.featureMember[0].GeoObject
    )
  }
}
</script>
